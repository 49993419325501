<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Gerenciar Roles</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-body row">
            <div class="col-md-6">
              <base-input label="Nome da Role" v-model="search.key"/>
            </div>
            <div class="col-sm-3">
              <base-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="item in [5,10,25,50]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label=" ">
                <base-button type="success" @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i> Buscar
                </base-button>
              </base-input>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column :min-width="60" label="ID">
                  <template slot-scope="props">
                      <span>
                        <span><b>#{{props.row.id}}</b></span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Role">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.authority}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :width="160" fixed="right" class-name="td-actions">
                  <template slot-scope="props">
                    <button v-show="props.row.authority!=roleDefaultApi" type="button" class="btn btn-sm btn-info"
                            title="Packs"
                            @click="loadDataPacks(props.row.id, props.row.authority)">
                      <i class="fas fa-box-open"></i>
                    </button>
                    <button v-show="props.row.authority!=roleDefaultApi" type="button" class="btn btn-sm btn-danger"
                            title="Usuários"
                            @click="loadDataUsers(props.row.id, props.row.authority)">
                      <i class="far fa-user"></i>
                    </button>
                    <button v-show="props.row.authority!=roleDefaultApi" type="button" class="btn btn-sm btn-warning"
                            title="Editar"
                            @click="edit(props.row.id, props.row.authority,
                              props.row.description.description, props.row.description.internalDetails)">
                      <i class="fas fa-edit"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info  mt-3">
              <p class="category"> Mostrando {{pagination.fromNumber}} até {{pagination.toNumber}}, de
                {{pagination.totalNumber}} registros.</p>
            </div>
            <div class="col-sm-6  mt-3">
              <p-pagination class="float-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadData">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="pack.tableData.length>0" style="max-width: 800px; margin: 0 auto">
        <card>
          <h4>Packs da Role <b>{{pack.authorityRole}}</b></h4>
          <div class="card-body row">
            <div class="col-md-6">
              <base-input label="Nome da Pack" v-model="pack.name"/>
            </div>
            <div class="col-sm-3">
              <base-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pack.pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="itemRole in [5,10,25,50]"
                    :key="itemRole"
                    :label="itemRole"
                    :value="itemRole">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label=" ">
                <base-button type="success" @click="findPacks(pack.idRole, pack.authorityRole)">
                  <i slot="label" class="nc-icon nc-check-2"></i> Buscar
                </base-button>
              </base-input>
            </div>
            <div class="col-sm-12">
              <el-table class="table-striped"
                        header-row-class-name="text-primary"
                        :data="pack.tableData">
                <el-table-column label="Pack">
                  <template slot-scope="props">
                        <span>
                          <span><b>{{props.row.name}}</b></span>
                        </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="Ativo">
                  <template slot-scope="props">
                    <input type="checkbox" v-model="props.row.isAssociatePack"
                           @change="updateAssociatePacks(props.row.id, props.row.isAssociatePack)">
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">EXIBINDO DO {{pack.pagination.fromNumber}} AO {{pack.pagination.toNumber}}, DE
                {{pack.pagination.totalNumber}} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pack.pagination.currentPage"
                            :per-page="pack.pagination.perPage"
                            :total="pack.pagination.totalNumber"
                            :click="findPackPagination">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="user.tableData.length>0" style="max-width: 800px; margin: 0 auto">
        <card>
          <h4>Usuários da Role <b>{{user.authorityRole}}</b></h4>
          <div class="card-body row">
            <div class="col-md-4">
              <base-input label="Usuário" v-model="user.username"/>
            </div>
            <div class="col-sm-2">
              <base-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="user.pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="itemPack in [5,10,25,50]"
                    :key="itemPack"
                    :label="itemPack"
                    :value="itemPack">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label="Status">
                <el-select size="large"
                           class="select-default"
                           placeholder="Selecionar"
                           v-model="user.status">
                  <el-option value="ALL"
                             class="select-default"
                             label="Todos"/>
                  <el-option value="TRUE"
                             class="select-default"
                             label="Ativo"/>
                  <el-option value="FALSE"
                             class="select-default"
                             label="Inativo"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label=" ">
                <base-button type="success" @click="findUsers(user.idRole, user.authorityRole)">
                  <i slot="label" class="nc-icon nc-check-2"></i> Buscar
                </base-button>
              </base-input>
            </div>
            <div class="col-sm-12">
              <el-table class="table-striped"
                        header-row-class-name="text-primary"
                        :data="user.tableData">
                <el-table-column label="Username">
                  <template slot-scope="props">
                        <span>
                          <span><b>@{{props.row.username}}</b></span>
                        </span>
                  </template>
                </el-table-column>
                <el-table-column label="E-mail">
                  <template slot-scope="props">
                        <span>
                          <span>{{props.row.email}}</span>
                        </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="Ativo">
                  <template slot-scope="props">
                    <input type="checkbox" v-model="props.row.isAssociateUser"
                           @change="updateAssociateUsers(props.row.username, props.row.isAssociateUser)">
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info  mt-3">
              <p class="category"> Mostrando {{pagination.fromNumber}} até {{pagination.toNumber}}, de
                {{pagination.totalNumber}} registros.</p>
            </div>
            <div class="col-sm-6  mt-3">
              <p-pagination class="float-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadData">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="id!=''" style="max-width: 800px; margin: 0 auto">
        <card>
          <h4>Editar a Role <b>{{name}}</b></h4>
          <form>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <label>Autorização</label>
                  <input type="text" class="form-control" maxlength="30" v-model="name"
                         style="text-transform: uppercase"></input>
                </div>
              </div>
              <div class="row form-group" style="margin-top: 10px">
                <div class="col-sm-6">
                  <label>Descrição</label>
                  <textarea class="form-control" rows="5" maxlength="255" v-model="description"></textarea>
                </div>
                <div class="col-sm-6">
                  <label>Detalhes Internos</label>
                  <textarea class="form-control" rows="5" maxlength="1000" v-model="internalDetails"></textarea>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button type="info" @click.prevent="updateRole">Salvar</button>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from '../../../components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "@/ws.service"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        roleDefaultApi: 'DIRECT_API',
        search: {
          key: '',
          documentStatus: null,
          dateOrder: 'ASC'
        },
        id: '',
        name: '',
        description: '',
        internalDetails: '',
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        pack: {
          name: '',
          authorityRole: '',
          idRole: '',
          tableData: [],
          listIncludeIdPacks: [],
          listDeleteIdPacks: [],
          pagination: {
            perPage: 5,
            currentPage: 1,
            pageMaxNumber: 1,
            fromNumber: 0,
            toNumber: 0,
            totalNumber: 0
          }
        },
        user: {
          username: '',
          authorityRole: '',
          idRole: '',
          status: 'ALL',
          tableData: [],
          listIncludeUsernames: [],
          listDeleteUsernames: [],
          pagination: {
            perPage: 5,
            currentPage: 1,
            pageMaxNumber: 1,
            fromNumber: 0,
            toNumber: 0,
            totalNumber: 0
          }
        }
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        this.id = ''
        this.user.tableData = []
        this.pack.tableData = []
        postWs("/role/get-all-roles",
          true, null,
          {
            name: this.search.key,
            pageNumber: this.pagination.currentPage,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      findPacks(id, name) {
        this.pack.pagination.currentPage = 1
        this.loadDataPacks(id, name)
      },
      findPackPagination() {
        this.loadDataPacks(this.pack.idRole, this.pack.authorityRole)
      },
      loadDataPacks(id, name) {
        this.id = ''
        this.user.tableData = []
        if (name != this.pack.authorityRole) {
          this.pack.pagination.currentPage = 1
        }
        this.pack.authorityRole = name
        this.pack.idRole = id
        postWs("/role/get-role-packs",
          true, null,
          {
            id: id,
            name: this.pack.name,
            pageNumber: this.pack.pagination.currentPage,
            pageSize: this.pack.pagination.perPage
          }, this.successLoadDataPack,
          failWs)
        return this.pack.pagination.currentPage
      },
      successLoadDataPack(response) {
        if (this.pack.name != '' && response.data.list.length == 0) {
          this.pack.name = ''
          this.$swal({
            title: 'Aviso!',
            text: "Nenhuma pack encontrada com esse nome!",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
        } else {
          this.pack.tableData = response.data.list
          this.pack.pagination.totalNumber = response.data.totalNumber
          this.pack.pagination.pageMaxNumber = response.data.pageMaxNumber
          if (this.pack.pagination.totalNumber > 0) {
            this.pack.pagination.fromNumber = ((this.pack.pagination.perPage * (this.pack.pagination.currentPage - 1)) + 1)
            this.pack.pagination.toNumber = ((this.pack.pagination.fromNumber + this.pack.tableData.length) - 1)
          } else {
            this.pack.pagination.fromNumber = 0
            this.pack.pagination.toNumber = 0
          }
        }
      },
      updateAssociatePacks(id, active) {
        if (active) {
          this.pack.listIncludeIdPacks = [id];
          this.pack.listDeleteIdPacks = [];
        } else {
          this.pack.listDeleteIdPacks = [id];
          this.pack.listIncludeIdPacks = [];
        }

        postWs("/role/associate-role-packs",
          true, null,
          {
            idRole: this.pack.idRole,
            listIncludeIdPacks: this.pack.listIncludeIdPacks,
            listDeleteIdPacks: this.pack.listDeleteIdPacks
          }, this.wsOk,
          failWs)
      },
      findUsers(id, name) {
        this.user.pagination.currentPage = 1
        this.loadDataUsers(id, name)
      },
      findUsersPagination() {
        this.loadDataUsers(this.user.idRole, this.user.authorityRole)
      },
      loadDataUsers(id, name) {
        this.id = ''
        this.pack.tableData = []
        if (name != this.user.authorityRole) {
          this.user.pagination.currentPage = 1
        }
        this.user.authorityRole = name
        this.user.idRole = id
        postWs("/role/get-role-users",
          true, null,
          {
            id: id,
            name: this.user.username,
            active: this.user.status,
            pageNumber: this.user.pagination.currentPage,
            pageSize: this.user.pagination.perPage
          }, this.successLoadDataUsers,
          failWs)
        return this.user.pagination.currentPage
      },
      successLoadDataUsers(response) {
        if (this.user.username != '' && response.data.list.length == 0) {
          this.user.username = ''
          this.$swal({
            title: 'Aviso!',
            text: "Nenhum usuário encontrado com esse username!",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
        } else {
          this.user.tableData = response.data.list
          this.user.pagination.totalNumber = response.data.totalNumber
          this.user.pagination.pageMaxNumber = response.data.pageMaxNumber
          if (this.user.pagination.totalNumber > 0) {
            this.user.pagination.fromNumber = ((this.user.pagination.perPage * (this.user.pagination.currentPage - 1)) + 1)
            this.user.pagination.toNumber = ((this.user.pagination.fromNumber + this.user.tableData.length) - 1)
          } else {
            this.user.pagination.fromNumber = 0
            this.user.pagination.toNumber = 0
          }
        }
      },
      updateAssociateUsers(username, active) {
        if (active) {
          this.user.listIncludeUsernames = [username];
          this.user.listDeleteUsernames = [];
        } else {
          this.user.listDeleteUsernames = [username];
          this.user.listIncludeUsernames = [];
        }

        postWs("/role/associate-role-users",
          true, null,
          {
            idRole: this.user.idRole,
            listIncludeUsernames: this.user.listIncludeUsernames,
            listDeleteUsernames: this.user.listDeleteUsernames
          }, this.wsOk,
          failWs)
      },
      wsOk(response) {
        return true;
      },
      edit(id, name, description, internalDetails) {
        this.pack.tableData = []
        this.user.tableData = []
        this.id = id
        this.name = name
        this.description = description
        this.internalDetails = internalDetails
      },
      updateRole() {
        callWs("/role/update-role", true,
          "POST", null, true, null,
          {
            id: this.id, name: this.name.toUpperCase(),
            description: this.description, internalDetail: this.internalDetails
          },
          () => {
            this.$swal({
              title: 'Sucesso!',
              text: 'Dados salvos com sucesso.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              timer: 5000,
              icon: 'success'
            })
          }, (error) => {
            this.buttonDisabled = false
            if (error.response.status == 409) {
              this.$swal({
                title: 'Aviso!',
                text: 'Authority já existe para outra Role cadastrada!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                timer: 5000,
                icon: 'warning'
              })
              return
            }
            this.$swal({
              title: 'Falha!',
              text: 'Falha ao atualizar a role, verifique os dados e tente novamente!',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger btn-fill'
              },
              timer: 5000,
              icon: 'error'
            })
          })
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }

  .btn.btn-primary {
    margin-right: 10px;
  }
</style>
